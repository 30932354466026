import { active_project }         from 'state/store.global'
import { vis_filter, vis_detail } from 'state/store.global'
import { vis_desktop_nav }        from 'state/store.global'
import { useGet }                 from 'state/jotai'
import { Content, Root }          from './Layout.style'
import { DualMenu }               from 'components/DualMenu/DualMenu'
import { TopMenu }                from 'components/TopMenu/TopMenu'
import { useLocation }            from 'react-router-dom'
import { useBreak }               from 'hooks/useBreak'
import { useEffect, useRef } from 'react'
import useUnitPage from 'components/Unit/useUnitPage'
import { Scrollbar } from 'react-scrollbars-custom'

export const Layout = ({ children }) => {
  
  const here = useLocation().pathname
  const isDesktop    = useBreak('md_up')

  const { Navigation, DrawersLeft, DrawersRight, Views } = children
  
  const pin   = useGet(active_project).pin
  const left  = useGet(vis_filter)
  const right = useGet(vis_detail)
  const nav   = useGet(vis_desktop_nav)
  const activeURL = ["list", "compare", "unit", "isometry", "gallery", "downloads", "favorites"]
  const activeURLindex = activeURL.findIndex(i => here.includes(i))

  const style = {
    "isometry": {
      marginTop: "72px",
      height: isDesktop ? pin > 0 ? "calc(100% - 135px)" : "calc(100% - 72px)" : "calc(100% - 89px)"
    },
    "list": {
      marginTop: isDesktop ? "72px" : "142px",
      height: isDesktop ? "calc(100% - 158px)" : right ? "500px" : "calc(100% - 172px)",
      paddingBottom: isDesktop ? '' : "77px",
      overflow: right ? 'hidden' : 'auto',
    },
    "unit": {
      marginTop: isDesktop ? "72px" : "69px",
      height: isDesktop ? "calc(100% - 72px)" : "calc(100% - 69px)",
    },
    "compare": {
      marginTop: isDesktop ? "200px" : "170px",
      height: isDesktop ? "calc(100% - 200px)" : "calc(100% - 119px)",
      overflow: "hidden"
    },
    "favorites": {
      marginTop: isDesktop ? "119px" : "119px",
      height: isDesktop ? "calc(100% - 119px)" : "calc(100% - 119px)",
    },
    "gallery": {
      marginTop: isDesktop ? "125px" : "125px",
      height: isDesktop ? "calc(100% - 125px)" : "calc(100% - 125px)"
    },
    "downloads": {
      marginTop: isDesktop ? "135px" : "119px",
      height: isDesktop ? "calc(100% - 135px)" : "calc(100% - 119px)"
    }
  }
  
  const rootStyle = {
    "isometry": {
      height: "100%",
      overflowY: "hidden"
    }
  }

  
  return (
    <Root styles={rootStyle[activeURL[activeURLindex]]}>
      { pin >= 0 && DrawersLeft.map(i => i) }
      { pin >= 0 && DrawersRight.map(i => i) }
      <Content left={left} right={right} nav={nav} styles={style[activeURL[activeURLindex]]}>
        { Views }
      </Content>
      <TopMenu left={left} right={right} nav={nav}/>
      <DualMenu left={left} right={right} nav={nav}/>
      { Navigation }
    </Root>
  )
}