import { ImgWrapper } from 'components/ImgContainer/ImgWrapper';
import { getImages, getThumb } from 'components/List/List.Utilities';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get, useGet } from 'state/jotai';
import { getIsoData } from 'state/jotai.isometryDataHooks';
import { active_project } from 'state/store.global';
import { _project } from 'state/store.projects';

export const BackgroundGallaryLoading = () => {
  const gallery = get(_project, "gallery");
  const photos = gallery?.Data?.ListOfPhotos ?? [];
  const here = useLocation().pathname;
  const atList = here.includes('list');

  const [startLoading, setStartLoading] = useState(false);
  const [range, setRange] = useState([0, 20]); // Start with range [0, 20]
  const loadBatchSize = 20; // Number of units to load in each chunk

  const { pin, pid } = useGet(active_project);

  useEffect(() => {
    if (photos && photos.length) {
      setStartLoading(true);
    }
  }, [pin, pid, photos.length]);

  useEffect(() => {
    if (startLoading && range[1] < photos.length) {
      // Schedule loading the next batch after some delay
      const timer = setTimeout(() => {
        setRange((prevRange) => [prevRange[1], prevRange[1] + loadBatchSize]);
      }, 1000); // Adjust delay as needed to control the loading rate

      return () => clearTimeout(timer);
    }
  }, [startLoading, range, photos.length]);

  const loadImagesForUnit = (photo, j) => {
    
    return (
      <div key={j} style={{ display: 'none' }}>
          <div key={j + 'gallaryImage'} style={{ display: 'none' }}>
            <ImgWrapper priority={3} bgLoad={true} img={photo.Url} />
          </div>
      </div>
    );
  };

  return (
    <>
      {startLoading &&
        photos.slice(range[0], range[1]).map((photo, j) => loadImagesForUnit(photo, j))
      }
    </>
  );
};
