import { ImgWrapper } from 'components/ImgContainer/ImgWrapper';
import { getImages, getThumb } from 'components/List/List.Utilities';
import { useActiveUnit } from 'components/Unit';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get, useGet } from 'state/jotai';
import { getIsoData } from 'state/jotai.isometryDataHooks';
import { active_project } from 'state/store.global';
import { _project } from 'state/store.projects';

export const BackgroundUnitLoading = () => {
  const here = useLocation().pathname;
  const atList = here.includes('list');
  const activeUnit = useActiveUnit();

  const [startLoading, setStartLoading] = useState(false);
  const [range, setRange] = useState([0, 5]); // Start with range [0, 5] for the first image and thumbnail
  const [firstBatchDone, setFirstBatchDone] = useState(false); // Track if the first images are loaded

  const loadBatchSize = 10; // Number of units to load in each chunk
  const loadInterval = 10000; // Interval between each image load (in ms)
  const { pin, pid } = useGet(active_project);
  const status = getIsoData('status');
  const units = get(_project, 'units', 'Units', false) || [];

  // Start loading when certain conditions are met
  useEffect(() => {
    if (units && units.length && (atList || status === 'DONE')) {
      setStartLoading(true);
    }
  }, [pin, pid, atList, status, units.length]);

  // Manage the interval for fetching images
  useEffect(() => {
    let interval;
    if (startLoading) {
      if (!firstBatchDone && range[1] < units.length) {
        interval = setInterval(() => {
          setRange((prevRange) => [prevRange[1], Math.min(prevRange[1] + loadBatchSize, units.length)]);
        }, loadInterval); // Delay between fetching each batch
      } else if (range[1] >= units.length && !firstBatchDone) {
        // Mark that the first batch (first images) is done
        setFirstBatchDone(true);
        setRange([0, 5]); // Reset the range to start fetching 2-3 next images
      }
    }
    return () => clearInterval(interval);
  }, [startLoading, range, units.length, firstBatchDone]);

  // Function to load images for a unit
  const loadImagesForUnit = (unit, j, loadStage = 'initial') => {
    const sliderImages = getImages(unit, true) ?? [];
    const sliderThumbImages = getThumb(unit, true) ?? [];

    // Initial load: first image and thumbnail (A image)
    if (loadStage === 'initial') {
      return (
        <div key={j} style={{ display: 'none' }}>
          <ImgWrapper priority={1} bgLoad={true} img={sliderImages[0]?.url} />
          <ImgWrapper priority={1} bgLoad={true} img={sliderThumbImages[0]?.url} />
        </div>
      );
    }

    // Secondary load: 2-3 next images after the first one
    if (loadStage === 'secondary') {
      const nextImages = sliderImages.slice(1, 4); // Get the next 2-3 images (skip first)
      const nextThumbs = sliderThumbImages.slice(1, 4); // Get the next thumbnails (skip first)
      return (
        <div key={j} style={{ display: 'none' }}>
          {nextImages.map((img, i) => (
            <div key={i + j + 'sliderImage'} style={{ display: 'none' }}>
              <ImgWrapper priority={2} bgLoad={true} img={img?.url} />
            </div>
          ))}
          {nextThumbs.map((img, i) => (
            <div key={i + j + 'sliderThumbImage'} style={{ display: 'none' }}>
              <ImgWrapper priority={2} bgLoad={true} img={img?.url} />
            </div>
          ))}
        </div>
      );
    }

    if (loadStage === 'primary') {
      return (
        <div key={j} style={{ display: 'none' }}>
            {sliderThumbImages.map((img, i) => (
            <div key={i + j} style={{ display: 'none' }}>
              <ImgWrapper priority={1} bgLoad={true} img={img?.url} />
            </div>
          ))}
          {sliderImages.map((img, i) => (
            <div key={i + j} style={{ display: 'none' }}>
              <ImgWrapper priority={1} bgLoad={true} img={img?.url} />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      {/* Initial load: first image and thumbnails */}
      {startLoading && !firstBatchDone && 
        units.slice(0, range[1]).map((unit, j) => loadImagesForUnit(unit, j, 'initial'))
      }

      {/* Secondary load: 2-3 next images */}
      {firstBatchDone && 
        units.slice(0, range[1]).map((unit, j) => loadImagesForUnit(unit, j, 'secondary'))
      }
      {
        loadImagesForUnit(activeUnit.data, 'allUnitImageLoading...', 'primary')
      }
    </>
  );
};
